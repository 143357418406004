import React, { useState, useEffect } from "react";

export default function FooterBar() {
  return (
    <div className="text-white text-sm font-medium bg-gray-800 py-3">
      <div className="container px-3 mx-auto flex flex-col md:flex-row justify-center md:justify-between items-center">
        <div className=" cursor-pointer text-center">
          ©Copyright 2021. Designed and developed by Buttersquad Studio.
        </div>
        <div className=" cursor-pointer">All right reserved.</div>
      </div>
    </div>
  );
}
