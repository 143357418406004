import React, { useState, useEffect } from "react";

import bg1_desktop from "../asset/image/home/bg1-desktop.png";
import bg1_mobile from "../asset/image/home/bg1-mobile.png";
import bg2 from "../asset/image/home/bg2.png";
import s1 from "../asset/image/home/s1.png";
import s2 from "../asset/image/home/s2.png";

import NavBar from "./Component/Navbar";
import FooterBar from "./Component/Footer";

import useWindowSize from "./Component/useWindowSize";
import {
  client_update_userdata,
  useCurrentUserData,
} from "../firebase/useFireEvent";
import { UploadImage } from "../firebase/useFireStorage";
import { useFireUser } from "../firebase/useFireUser";

const ticket_price = {
  mdta_member: 450,
  pjpm: 450,
  student: 400,
  non_member: 1200,
};
const challenge_price = { join: 100, no: 0 };
export default function UserPage() {
  const userData = useCurrentUserData();
  const user = useFireUser();
  const [imgfile, setimgfile] = useState();
  const windowSize = useWindowSize();
  if (!windowSize) return null;

  async function oc_file(e) {
    if (e.target.files[0]) {
      const data = e.target.files[0];
      const receiptURL = await UploadImage(data, user.email);
      const asd = await client_update_userdata(user.email, {
        receiptURL,
        status: "pending",
      });
    }
  }

  if (!userData) return null;
  if (userData.receiptURL && userData.receiptURL !== imgfile) {
    setimgfile(userData.receiptURL);
  }

  const status = userData.status ?? "upload";
  const canUpdateReceipt = userData.status !== "complete";
  return (
    <div
      className="flex flex-col w-full"
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${bg2})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* ========== NAV BAR ========== */}
      <NavBar />
      {/* ========== ROW 1 ========== */}
      <div className="ROW1 px-3" style={{ minHeight: "100vh" }}>
        <div
          className="container mx-auto h-full relative bg-white p-5 my-20 rounded-xl shadow-xl"
          style={{ maxWidth: "700px" }}
        >
          <div className="text-blue-ci">
            <p className="font-bold  text-3xl md:text-2xl uppercase">
              Evolution of Innovation
              <br /> & Technology in Dentistry
            </p>
            <p className="font-medium text-sm md:text-base mt-3">
              7th Malaysia Dental Technologist Conference & Exhibition
            </p>
          </div>

          <div className="h-px bg-gray-300 -mx-5 my-8"></div>

          <div className="a">
            <div className="items-center md:items-start flex flex-col md:flex-row text-blue-ci rounded-md mt-5">
              <p className="text-black font-medium text-lg">Payment Guidance</p>

              {/* <div className="border-2 flex-center p-2 px-5 rounded-md text-base font-bold">
      pending receipt
    </div> */}
            </div>

            <div className="flex flex-col space-y-2 mt-3">
              <div className="border border-blue-ci p-3 rounded-lg">
                <p className="font-medium text-blue-ci">Step 1</p>
                <p className="a">
                  Make payment of{" "}
                  <span className="font-bold">
                    {" "}
                    RM{" "}
                    {ticket_price[userData.ticket_type] +
                      challenge_price[userData.challenge]}
                    .00
                  </span>{" "}
                  to{" "}
                  <span className="font-medium underline">
                    MALAYSIA DENTAL TECHNOLOGIST ASSOCIATION
                  </span>{" "}
                  <span className="font-bold uppercase">Public Bank</span>{" "}
                  Account.
                </p>
                <p>
                  Account No.: <span className="font-bold">310 599 7602</span>
                </p>
              </div>

              <div className="border border-blue-ci p-3 rounded-lg">
                <p className="font-medium text-blue-ci">Step 2</p>
                <p className="a">Upload your payment receipt here</p>
                {status === "upload" && (
                  <div className="flex mt-2">
                    <label htmlFor="receipt">
                      <div className=" mt-5 md:mt-0 font-medium bg-blue-ci text-white p-2 px-5 rounded-md cursor-pointer ">
                        Upload Receipt
                      </div>
                      <input
                        onChange={oc_file}
                        type="file"
                        className="hidden"
                        name="receipt"
                        id="receipt"
                      />
                    </label>
                  </div>
                )}

                {imgfile && (
                  <div className="a">
                    <img
                      src={imgfile}
                      className="w-full mt-8 rounded-md"
                      alt=""
                    />

                    {canUpdateReceipt && (
                      <label htmlFor="receipt">
                        <div className="mt-5 text-center font-medium bg-blue-ci text-white p-2 px-5 rounded-md cursor-pointer ">
                          Update Another Receipt
                        </div>
                        <input
                          onChange={oc_file}
                          type="file"
                          className="hidden"
                          name="receipt"
                          id="receipt"
                        />
                      </label>
                    )}
                  </div>
                )}
              </div>

              <div className="border border-blue-ci p-3 rounded-lg">
                <p className="font-medium text-blue-ci">Step 3</p>
                <p className="a">Your payment status will be updated here</p>
                {status === "pending" && (
                  <div className="mt-3 font-medium border-blue-ci border-2  p-2 px-5 rounded-md cursor-pointer text-blue-ci capitalize">
                    Payment status - pending for verification
                  </div>
                )}

                {status === "complete" && (
                  <div className="mt-3 font-medium bg-green-500 p-2 px-5 rounded-md cursor-pointer text-white 1capitalize">
                    Payment status - completed
                  </div>
                )}

                {status === "upload" && (
                  <div className="flex mt-2">
                    <label htmlFor="receipt">
                      <div className=" mt-3 font-medium border-blue-ci border-2  p-2 px-5 rounded-md cursor-pointer text-blue-ci capitalize">
                        Payment status - pending for receipt
                      </div>
                    </label>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="h-px bg-gray-300 -mx-5 my-8"></div>

          <div className="a">
            <p className="text-black font-medium text-lg mt-5">
              Registrar Details
            </p>
            <div className="mt-3 space-y-2">
              {userData.number && (
                <>
                  <p className="text-sm text-gray-500 capitalize">number </p>
                  <div
                    className="bg-gray-100 rounded-md p-2.5 mt-1 uppercase
                  "
                  >
                    <p className=" font-mono">
                      {userData.number.toString().padStart(3, "0")}
                    </p>
                  </div>
                </>
              )}
              <p className="text-sm text-gray-500 capitalize">title </p>
              <div className="bg-gray-100 rounded-md p-2.5 mt-1 uppercase">
                <p>{userData.title}</p>
              </div>

              <p className="text-sm text-gray-500 capitalize">Full name </p>
              <div className="bg-gray-100 rounded-md p-2.5 mt-1 uppercase">
                <p>{userData.fullname}</p>
              </div>

              <p className="text-sm text-gray-500 capitalize">Malaysia IC </p>
              <div className="bg-gray-100 rounded-md p-2.5 mt-1 uppercase">
                <p>{userData.ic_number}</p>
              </div>

              <p className="text-sm text-gray-500 capitalize">Contact number</p>
              <div className="bg-gray-100 rounded-md p-2.5 mt-1 uppercase">
                <p>{userData.phone_number}</p>
              </div>

              <p className="text-sm text-gray-500 capitalize">Address</p>
              <div className="bg-gray-100 rounded-md p-2.5 mt-1 uppercase">
                <p>{userData.address}</p>
              </div>

              <p className="text-sm text-gray-500 capitalize">Poskod</p>
              <div className="bg-gray-100 rounded-md p-2.5 mt-1 uppercase">
                <p>{userData.poskod}</p>
              </div>

              <p className="text-sm text-gray-500 capitalize">State</p>
              <div className="bg-gray-100 rounded-md p-2.5 mt-1 uppercase">
                <p>{userData.state}</p>
              </div>

              <p className="text-sm text-gray-500 capitalize">Country</p>
              <div className="bg-gray-100 rounded-md p-2.5 mt-1 uppercase">
                <p>{userData.country}</p>
              </div>

              <p className="text-sm text-gray-500 capitalize">
                Vegetarian Diet
              </p>
              <div className="bg-gray-100 rounded-md p-2.5 mt-1 uppercase">
                <p>{userData.vegetarian}</p>
              </div>
            </div>
          </div>

          <div className="h-px bg-gray-300 -mx-5 my-8"></div>

          <div className="a">
            <p className="text-black font-medium text-lg mt-5">
              Ticket Details
            </p>
            <div className="mt-3 space-y-2">
              <p className="text-sm text-gray-500 capitalize">Ticket Type </p>
              <div className="bg-gray-100 rounded-md p-2.5 mt-1 uppercase">
                <p>{userData.ticket_type}</p>
              </div>

              <p className="text-sm text-gray-500 capitalize">Challenge</p>
              <div className="bg-gray-100 rounded-md p-2.5 mt-1 uppercase">
                <p>
                  {userData.challenge === "no" ? "not interested" : "joined"}
                </p>
              </div>

              <p className="text-sm text-gray-500 capitalize">
                Poster Presentation
              </p>
              <div className="bg-gray-100 rounded-md p-2.5 mt-1 uppercase">
                <p>{userData.poster === "no" ? "not interested" : "joined"}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ========== FOOTER BAR ========== */}
      <FooterBar />
    </div>
  );
}
