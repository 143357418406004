import { initializeApp, getApps } from "@firebase/app";
import { useState, useEffect } from "react";
import { singletonHook } from "react-singleton-hook";

const useFirebaseAppImpl = () => {
  const [app, setApp] = useState(null);

  const firebaseConfig = {
    apiKey: "AIzaSyCjDa2gooqJ-16zB3Ul0KVnorISQxohuFs",

    authDomain: "mdta-event.firebaseapp.com",

    projectId: "mdta-event",

    storageBucket: "mdta-event.appspot.com",

    messagingSenderId: "239265887115",

    appId: "1:239265887115:web:1f569ca773edaf0162f58e",

    measurementId: "G-DXRJ26JT7N",
  };

  useEffect(() => {
    if (getApps().length > 0) return;
    console.log("FIREBASE @ initializeApp");
    setApp(initializeApp(firebaseConfig));
  }, []);
  return app;
};
export const useFirebaseApp = singletonHook(null, useFirebaseAppImpl);
