import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

export async function UploadImage(blob, email) {
  const storage = getStorage();
  const receiptRef = ref(storage, `receipt/${email}.jpg`);

  try {
    const res = await uploadBytes(receiptRef, blob);
    console.log("Upload success");
    return await getDownloadURL(res.ref);
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorCode);
    console.log(errorMessage);
  }
}
