import React, { useState, useEffect, useRef } from "react";

import bg from "../asset/image/login/registration-bg.jpeg";

import NavBar from "./Component/Navbar";
import FooterBar from "./Component/Footer";

import useWindowSize from "./Component/useWindowSize";

import { CreateFireUser, FireSignIn } from "../firebase/useFireUser";
import {
  useCurrentChallenge,
  useCurrentCounter,
  TriggerChallenge,
} from "../firebase/useFireEvent";
import { useHistory } from "react-router-dom";
export default function RegisterPage({ setRegisterSuccess }) {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [typePrice, setTypePrice] = useState(0);
  const [challengePrice, setChallengePrice] = useState(0);

  const windowSize = useWindowSize();
  const counter = useCurrentCounter();
  const history = useHistory();
  const challengeCounter = useCurrentChallenge();
  useEffect(() => {
    if (counter !== null && counter < 1) {
      alert("Ticket sold out");
      history.push("/");
    }
  }, [counter]);
  useEffect(() => {
    if (challengeCounter < 1) {
      setChallengePrice(0);
    }
  }, [challengeCounter]);

  if (!windowSize) return null;

  async function oc_submit(e) {
    e.preventDefault();

    if (isLoading) return;
    setError("");

    const data = new FormData(e.target);
    const obj = Object.fromEntries(data.entries());

    // sanitize
    for (var key of Object.keys(obj)) {
      obj[key] = obj[key].trim().toLowerCase();
    }
    // console.log(obj);

    if (obj.challenge === "join") {
      await TriggerChallenge();
    }

    // start execute
    setIsLoading(true);

    const res = await CreateFireUser(obj);
    setIsLoading(false);
    if (!res.isSuccess) {
      setError(res.errorMessage);
    }
    setRegisterSuccess(true);
  }
  const ticket_price = {
    pjpm: 450,
    mdta_member: 450,
    student: 400,
    non_member: 1200,
  };

  if (challengeCounter === null) {
    return <div className="flex-center h-screen">loading</div>;
  }
  return (
    <div
      className="flex flex-col w-full"
      style={{
        minHeight: "100vh",
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {/* ========== ROW 1 ========== */}
      <div className="flex-1 flex-center md:py-10">
        <form
          onSubmit={oc_submit}
          className="container mx-auto bg-white px-10 py-10 text-blue-ci font-medium"
          style={{ maxWidth: "420px" }}
        >
          <p className="text-2xl">Register</p>

          {/* CREDENTIAL */}
          <div className="space-y-5">
            <div className="mt-8">
              <p className="uppercase text-gray-600">
                IC number / Passport number (non malaysian){" "}
                <span className="text-red-500">*</span>
              </p>
              <input
                maxLength={12}
                required
                className="border w-full h-10 mt-3 px-3 border-gray-300"
                type="text"
                name="ic_number"
                onChange={(e) => {
                  const val = e.target.value;
                  const res = val.replace(/\D/g, "");
                  e.target.value = res;
                  if (res.length === 12) {
                    e.target.setCustomValidity("");
                  } else {
                    e.target.setCustomValidity("IC must be 12 digits");
                  }
                }}
                id=""
              />
            </div>

            <div className="mt-5">
              <p className="uppercase text-gray-600">
                phone number <span className="text-red-500">*</span>
              </p>
              <input
                onChange={(e) => {
                  const val = e.target.value;
                  const res = val.replace(/\D/g, "");
                  e.target.value = res;
                }}
                required
                className="border w-full h-10 mt-3 px-3 border-gray-300"
                type="text"
                name="phone_number"
                id=""
              />
            </div>
          </div>

          {/* DIVIDER */}
          <div
            className="h-px w-full bg-gray-200 my-8"
            style={{ height: "2px" }}
          ></div>

          {/* TIX */}
          <div className="space-y-5">
            <div className="mt-8">
              <p className="uppercase text-gray-600">
                Ticket Type <span className="text-red-500">*</span>
              </p>
              {[
                ["PJPM member (RM 450)", "pjpm"],
                ["MDTA member (RM 450)", "mdta_member"],
                ["Malaysian student (RM 400)", "student"],
                ["Non member (RM 1200)", "non_member"],
              ].map((x, i) => {
                return (
                  <div key={i} className="mt-3">
                    <label className="text-gray-400">
                      <input
                        onChange={(e) => {
                          setTypePrice(ticket_price[x[1]]);
                        }}
                        type="radio"
                        name="ticket_type"
                        value={x[1]}
                        required
                      />
                      <span className="ml-3">{x[0]}</span>
                    </label>
                  </div>
                );
              })}
            </div>

            <div className="mt-5">
              <p className="uppercase text-gray-600">LPO No. (optional)</p>
              <input
                onChange={(e) => {
                  const val = e.target.value;
                  const res = val.replace(/\D/g, "");
                  e.target.value = res;
                }}
                className="border w-full h-10 mt-3 px-3 border-gray-300"
                type="text"
                name="lpo"
                id=""
              />
            </div>

            {/* <div className="mt-8">
              <p className="uppercase text-gray-600">
                Ticket Type <span className="text-red-500">*</span>
              </p>
              <select
                className="mt-3 h-10 border border-gray-300 w-full px-3 bg-white"
                name="tix"
                required
              >
                <option value="" disabled selected>
                  - Select option -
                </option>
                <option value="pjpm">PJPM member (RM 450)</option>
                <option value="mdta">MDTA member (RM 450)</option>
                <option value="student">Malaysian student (RM 400)</option>
                <option value="public">Non member (RM 1200)</option>
              </select>
            </div> */}

            <div className="mt-8">
              <p className="uppercase text-gray-600">
                Dental Technologist Challenge{" "}
                <span className="text-red-500">*</span>
              </p>
              <a
                target={"_blank"}
                download
                href="https://firebasestorage.googleapis.com/v0/b/mdta-event.appspot.com/o/7th%20MDTC%20website%20registeration.pdf?alt=media&token=11479a66-8270-4b8c-b201-2814873a1f17"
                className=""
              >
                T&C apply, <span className="underline">Click here</span> to
                download
              </a>
              {challengeCounter > 0 && (
                <>
                  <div className="mt-3">
                    <input
                      onChange={(e) => {
                        setChallengePrice(100);
                      }}
                      type="radio"
                      id="join"
                      name="challenge"
                      value="join"
                      required
                    />
                    <label htmlFor="join" className="ml-3  text-gray-400">
                      Join ( RM 100 )
                    </label>
                  </div>
                  <div className="mt-1">
                    <input
                      onChange={(e) => {
                        setChallengePrice(0);
                      }}
                      type="radio"
                      id="challenge_no"
                      name="challenge"
                      value="no"
                      required
                    />
                    <label
                      htmlFor="challenge_no"
                      className="ml-3  text-gray-400"
                    >
                      Not Interested
                    </label>
                  </div>
                </>
              )}

              {challengeCounter < 1 && (
                <div className="mt-1">
                  <input
                    onChange={(e) => {
                      setChallengePrice(0);
                    }}
                    checked
                    type="radio"
                    id="challenge_no"
                    name="challenge"
                    value="no"
                    required
                  />
                  <label htmlFor="challenge_no" className="ml-3  text-gray-400">
                    fully registered
                  </label>
                </div>
              )}
            </div>

            <div className="mt-8">
              <p className="uppercase text-gray-600">
                Poster Presentation <span className="text-red-500">*</span>
              </p>
              <a
                target={"_blank"}
                download
                href="https://firebasestorage.googleapis.com/v0/b/mdta-event.appspot.com/o/7th%20MDTC%20website%20registeration.pdf?alt=media&token=11479a66-8270-4b8c-b201-2814873a1f17"
                className=""
              >
                T&C apply, <span className="underline">Click here</span> to
                download
              </a>
              <div className="mt-3">
                <input
                  type="radio"
                  id="poster_yes"
                  name="poster"
                  value="join"
                  required
                />
                <label htmlFor="poster_yes" className="ml-3  text-gray-400">
                  Join ( F.O.C )
                </label>
              </div>
              <div className="mt-1">
                <input
                  type="radio"
                  id="poster_no"
                  name="poster"
                  value="no"
                  required
                />
                <label htmlFor="poster_no" className="ml-3  text-gray-400">
                  Not Interested
                </label>
              </div>
            </div>
            {/* 
            <div className="mt-8">
              <p className="uppercase text-gray-600">
                Dental Technologist Challenge{" "}
                <span className="text-red-500">*</span>
              </p>
              <select
                className="mt-3 h-10 border border-gray-300 w-full px-3 bg-white"
                name="workshop1"
                required
              >
                <option value="" disabled selected>
                  - Select option -
                </option>
                <option value="join">Join (RM 100)</option>
                <option value="-">Not Interested</option>
              </select>
            </div> */}
          </div>

          {/* DIVIDER */}
          <div
            className="h-px w-full bg-gray-200 my-8"
            style={{ height: "2px" }}
          ></div>

          {/* PREFERENCE */}
          <div className="space-y-5">
            <div className="mt-8">
              <p className="uppercase text-gray-600">
                title <span className="text-red-500">*</span>
              </p>
              <div className="mt-3">
                <input
                  type="radio"
                  id="prof"
                  name="title"
                  value="prof"
                  required
                />
                <label htmlFor="prof" className="ml-3 text-gray-400">
                  Prof.
                </label>
              </div>
              <div className="mt-1">
                <input type="radio" id="dr" name="title" value="dr" required />
                <label htmlFor="dr" className="ml-3 text-gray-400">
                  Dr.
                </label>
              </div>
              <div className="mt-1">
                <input type="radio" id="mr" name="title" value="mr" required />
                <label htmlFor="mr" className="ml-3 text-gray-400">
                  Mr.
                </label>
              </div>
              <div className="mt-1">
                <input
                  type="radio"
                  id="mrs"
                  name="title"
                  value="mrs"
                  required
                />
                <label htmlFor="mrs" className="ml-3 text-gray-400">
                  Mrs.
                </label>
              </div>
              <div className="mt-1">
                <input type="radio" id="ms" name="title" value="ms" required />
                <label htmlFor="ms" className="ml-3 text-gray-400">
                  Ms.
                </label>
              </div>
            </div>
            {/* <div className="mt-8">
              <p className="uppercase text-gray-600">
                Title <span className="text-red-500">*</span>
              </p>
              <select
                className="mt-3 h-10 border border-gray-300 w-full px-3 bg-white"
                name="title"
                required
              >
                <option value="" disabled selected>
                  - Select option -
                </option>
                <option value="prof">Prof.</option>
                <option value="dr">Dr.</option>
                <option value="mr">Mr.</option>
                <option value="mrs">Mrs.</option>
                <option value="ms">Ms.</option>
              </select>
            </div> */}

            <div className="mt-8">
              <p className="uppercase text-gray-600">
                full name (as per IC) <span className="text-red-500">*</span>
              </p>
              <input
                placeholder="name on IC"
                className="border w-full h-10 mt-3 px-3 border-gray-300"
                type="text"
                name="fullname"
                required
              />
            </div>

            <div className="mt-8">
              <p className="uppercase text-gray-600">
                preferred name in nametag{" "}
                <span className="text-red-500">*</span>
              </p>
              <input
                placeholder="nickname"
                className="border w-full h-10 mt-3 px-3 border-gray-300"
                type="text"
                name="nickname"
                required
              />
            </div>

            <div className="mt-8">
              <p className="uppercase text-gray-600">
                email address <span className="text-red-500">*</span>
              </p>
              <input
                onChange={(e) => {
                  e.target.value = e.target.value.toLocaleLowerCase();
                }}
                placeholder="example@gmail.com"
                className="border w-full h-10 mt-3 px-3 border-gray-300"
                type="email"
                name="email"
                required
              />
            </div>

            <div className="mt-8">
              <p className="uppercase text-gray-600">
                Address <span className="text-red-500">*</span>
              </p>
              <input
                onChange={(e) => {
                  e.target.value = e.target.value.toLocaleLowerCase();
                }}
                placeholder="no.1, Jalan 1/1, Taman 1/1, Kuala Lumpur"
                className="border w-full h-10 mt-3 px-3 border-gray-300"
                type="text"
                name="address"
                required
              />
            </div>

            <div className="mt-8">
              <p className="uppercase text-gray-600">
                Poskod <span className="text-red-500">*</span>
              </p>
              <input
                placeholder="00000"
                className="border w-full h-10 mt-3 px-3 border-gray-300"
                type="number"
                name="poskod"
                pattern="[0-9]*"
                required
              />
            </div>

            <div className="mt-8">
              <p className="uppercase text-gray-600">
                State <span className="text-red-500">*</span>
              </p>
              <input
                onChange={(e) => {
                  e.target.value = e.target.value.toLocaleLowerCase();
                }}
                placeholder="kuala lumpur"
                className="border w-full h-10 mt-3 px-3 border-gray-300"
                type="text"
                name="state"
                required
              />
            </div>

            <div className="mt-8">
              <p className="uppercase text-gray-600">
                Country <span className="text-red-500">*</span>
              </p>
              <input
                onChange={(e) => {
                  e.target.value = e.target.value.toLocaleLowerCase();
                }}
                placeholder="malaysia"
                className="border w-full h-10 mt-3 px-3 border-gray-300"
                type="text"
                name="country"
                required
              />
            </div>

            <div className="mt-8">
              <p className="uppercase text-gray-600">
                vegetarian <span className="text-red-500">*</span>
              </p>
              <div className="mt-3">
                <input
                  type="radio"
                  id="yes"
                  name="vegetarian"
                  value="yes"
                  required
                />
                <label htmlFor="yes" className="ml-3 uppercase text-gray-400">
                  yes
                </label>
              </div>
              <div className="mt-1">
                <input
                  type="radio"
                  id="no"
                  name="vegetarian"
                  value="no"
                  required
                />
                <label htmlFor="no" className="ml-3 uppercase text-gray-400">
                  no
                </label>
              </div>
            </div>

            <div className="a">
              <p className="uppercase flex justify-between px-3 border-2 border-blue-ci py-3 text-blue-ci">
                Total Price :{" "}
                <span className="font-bold">
                  RM {typePrice + challengePrice}
                </span>
              </p>
            </div>
          </div>

          {/* ========== ERROR ========== */}
          {error !== "" && <p className="text-red-500 mt-3">{error}</p>}

          <p className="text-gray-400 mt-10 text-sm">
            By clicking Register, you agree to our Terms and that you have read
            our data use policy.{" "}
            <a
              target={"_blank"}
              download
              href="https://firebasestorage.googleapis.com/v0/b/mdta-event.appspot.com/o/7th%20MDTC%20website%20registeration.pdf?alt=media&token=11479a66-8270-4b8c-b201-2814873a1f17"
              className=""
            >
              <span className="underline">Click here</span> to check.
            </a>
          </p>

          <button className="mt-5 bg-blue-ci shadow text-center w-full p-2 h-12 text-white flex-center capitalize cursor-pointer">
            {isLoading ? (
              <span className="animate-spin material-icons">autorenew</span>
            ) : (
              "register"
            )}
          </button>
          <a href="/login">
            <div className="mt-5 bg-gray-100 shadow text-center p-2 h-12 text-gray-600 flex-center capitalize cursor-pointer">
              login
            </div>
          </a>
          <a href="/">
            <div className="mt-5 bg-gray-100 shadow text-center p-2 h-12 text-gray-600 flex-center capitalize cursor-pointer">
              back to home
            </div>
          </a>
        </form>
      </div>
      {/* ========== FOOTER BAR ========== */}
      <FooterBar />
    </div>
  );
}
