import { singletonHook } from "react-singleton-hook";
import React, { useState, useEffect } from "react";

let publicAdminState = null;
const useAdminStateImpl = () => {
  const [state, setState] = useState(null);
  useEffect(() => {
    publicAdminState = setState;
    setState({
      showUserPopup: false,
    });
  }, []);
  return state;
};

export const useAdminState = singletonHook(null, useAdminStateImpl);

export function ToggleUserPopup(params) {
  publicAdminState((prev) => {
    const newState = { ...prev };
    newState.showUserPopup = !newState.showUserPopup;
    newState.targetUser = params ?? null;
    return newState;
  });
}
