import React, { useState, useEffect } from "react";

import "./App.css";

import { useFirebaseApp } from "./firebase/useFirebaseApp";

import AdminPage from "./page/AdminPage";
import HomePage from "./page/HomePage";
import LoginPage from "./page/LoginPage";
import RegisterPage from "./page/RegisterPage";
import UserPage from "./page/UserPage";

import bg1_desktop from "./asset/image/home/bg1-desktop.png";
import bg1_mobile from "./asset/image/home/bg1-mobile.png";
import useWindowSize from "./page/Component/useWindowSize";
import moment from "moment";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";

import { useFireStore } from "./firebase/useFireEvent";
import { useFireUser } from "./firebase/useFireUser";

function App() {
  const [devMode, setdevMode] = useState(true);
  const [registerSuccess, setRegisterSuccess] = useState(false);
  useEffect(() => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      // dev code
      setdevMode(true);
    } else {
      setdevMode(false);
    }
  }, []);

  const user = useFireUser();
  const isAdmin = user?.email.includes("@admin.com") ?? null;

  const windowSize = useWindowSize();
  if (!windowSize) return null;

  <>
    <div className="absolute h-16 md:h-16 w-full z-10 bg-blue-ci">
      <div
        className="container px-3 mx-auto h-full 
     space-x-3 flex-center"
      >
        <p className="text-white text-center text-lg font-semibold">
          Please kindly wait for organizer to announce the date
        </p>
        <div className="flex space-x-3"></div>
      </div>
    </div>

    <div
      className="ROW1"
      style={{
        height: "100vh",
        minHeight: "500px",
        backgroundImage: `url(${
          windowSize.isMobile ? bg1_mobile : bg1_desktop
        })`,
        backgroundSize: "cover",
        backgroundPosition: "right",
      }}
    >
      <div className="container mx-auto h-full relative">
        <div className="absolute left-5 top-1/3 md:top-1/2 transform -translate-y-1/2 text-blue-ci">
          <p className="font-medium text-sm md:text-lg">
            7th Malaysia Dental Technologist Conference & Exhibition
          </p>
          <p className="font-bold  text-3xl md:text-5xl uppercase mt-8">
            Evolution of Innovation
            <br /> & Technology in Dentistry
          </p>
          <div className="flex mt-10 md:mt-20">
            <div className=" text-blue-ci font-medium rounded-full flex-center py-3 px-8 cursor-pointer border-2 border-blue-ci ">
              To be announce
            </div>
          </div>
        </div>
      </div>
    </div>
  </>;

  return (
    <>
      <PreloadHook />
      <Router>
        <Switch>
          <Route path="/dev">
            {devMode ? <AdminPage /> : <Redirect to="/" />}
          </Route>

          <Route exact path="/">
            <HomePage />
          </Route>
          <Route exact path="/user">
            {user ? <UserPage /> : <Redirect to="/" />}
            {registerSuccess && (
              <div className="fixed top-0 left-0 z-20 w-screen h-screen flex-center">
                <div className="bg-black opacity-50 w-full h-full absolute"></div>
                <div className="relative py-5 px-5 bg-white rounded-xl mx-5">
                  <p className="text-blue-ci text-xl font-medium">
                    Register successful
                  </p>
                  <div className="text-green-600 leading-5 mt-1 ">
                    Please follow below steps to complete your payment
                  </div>
                  <div className="flex flex-col mt-3 space-y-1">
                    <div className="a">
                      1. Make payment to MALAYSIA DENTAL TECHNOLOGIST
                      ASSOCIATION Public Bank Account.
                    </div>
                    <div className="a">2. Upload your payment receipt</div>
                    <div className="a">
                      3. Your payment status will be updated here
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setRegisterSuccess(false);
                    }}
                    className="bg-blue-ci cursor-pointer text-white flex-center rounded-lg py-3 font-medium mt-5"
                  >
                    I understand
                  </div>
                </div>
              </div>
            )}
          </Route>
          <Route exact path="/login">
            {!user ? <LoginPage /> : <Redirect to="/user" />}
          </Route>
          <Route exact path="/register">
            {!user ? (
              <RegisterPage {...{ setRegisterSuccess }} />
            ) : (
              <Redirect to="/user" />
            )}
          </Route>
          <Route exact path="/admin">
            <AdminPage />
          </Route>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;

function PreloadHook(params) {
  useFireStore();
  useFirebaseApp();
  return null;
}
