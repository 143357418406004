import React, { useState, useEffect } from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";

import { FireSignOut, useFireUser } from "../../firebase/useFireUser";
import { useCurrentCounter } from "../../firebase/useFireEvent";

export default function NavBar() {
  const counter = useCurrentCounter();
  const user = useFireUser();
  const history = useHistory();
  const _location = useLocation();

  function oc_button(params) {
    if (user) {
      FireSignOut();
      window.location.reload();
    } else {
      history.push("/login");
    }
  }

  function oc_home(params) {
    history.push("/");
  }

  function oc_check(params) {
    history.push("/user");
  }

  const canBackToHome = user && _location.pathname !== "/";
  const canCheckStatus = user && _location.pathname === "/";

  return (
    <div className="fixed top-0 h-16 md:h-16 w-full z-10 bg-blue-ci shadow-lg">
      <div className="container px-3 mx-auto h-full flex justify-between items-center space-x-3">
        <div className="a"></div>
        <div className="flex space-x-3">
          {canCheckStatus && (
            <div
              onClick={oc_check}
              className="text-xs md:text-base uppercase cursor-pointer font-semibold bg-white rounded-full md:px-10 px-2 py-2 shadow border-2 border-blue-ci leading-none flex-center text-center"
            >
              check status
            </div>
          )}
          {canBackToHome && (
            <div
              onClick={oc_home}
              className="text-xs md:text-base uppercase cursor-pointer font-semibold bg-white rounded-full md:px-10 px-2 py-2 shadow border-2 border-blue-ci "
            >
              home
            </div>
          )}
          <div
            onClick={oc_button}
            className="text-xs md:text-base uppercase cursor-pointer font-semibold bg-white rounded-full px-2 md:px-10 py-2 shadow border-2 border-blue-ci leading-none flex-center"
          >
            {user ? "logout" : "login"}
          </div>
        </div>
      </div>
    </div>
  );
}
