import { useFirebaseApp } from "./useFirebaseApp";
import { getFirestore, increment } from "firebase/firestore";

import { useState, useEffect } from "react";
import { singletonHook } from "react-singleton-hook";
import { getAuth } from "firebase/auth";
import {
  collection,
  getDoc,
  addDoc,
  getDocs,
  setDoc,
  query,
  where,
  updateDoc,
  writeBatch,
  doc,
  onSnapshot,
  documentId,
  serverTimestamp,
  deleteDoc,
  get,
} from "firebase/firestore";
import { findAllInRenderedTree } from "react-dom/test-utils";

var _ = require("lodash");

let app = null;
let db = null;

// ============================================================================
// Init Firestore Service
// ============================================================================

const useFireStoreImpl = () => {
  const [state, setState] = useState("");
  app = useFirebaseApp();
  useEffect(() => {
    if (!app) return;
    db = getFirestore();
    console.log(`FIREEVENT @ Init`);
    // Admin_CreateUser();
  }, [app]);
  return state;
};

export const useFireStore = singletonHook("", useFireStoreImpl);

// ============================================================================
// Client Only
// ============================================================================

export async function client_check_test(email, callback) {
  const userDoc = doc(db, "user_data", email);
  if (!userDoc.exists()) {
    callback(false);
  } else {
    const data = userDoc.data();
    if (!data.test) {
      const result = await updateDoc(doc(db, "user_data", email), {
        test: serverTimestamp(),
      });
      callback("Your entry has been submitted, thank you for your cooperation");
    } else {
      callback(
        `You have checked in at ${data.test.toDate().toLocaleTimeString()}`
      );
    }
  }
}

export async function client_create_userdata(email, data) {
  const userDoc = doc(db, "user_data", email);
  try {
    await setDoc(userDoc, { ...data, ts: Date.now() });
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorCode);
    console.log(errorMessage);
  }
}

export async function client_update_userdata(email, data) {
  const userDoc = doc(db, "user_data", email);
  try {
    await updateDoc(userDoc, data);
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorCode);
    console.log(errorMessage);
  }
}

export async function client_uploadImg() {}

const useCurrentUserDataImpl = () => {
  const [state, setState] = useState(null);
  app = useFirebaseApp();
  const auth = getAuth();

  useEffect(() => {
    if (!app || !auth) return;
    const email = auth.currentUser.email;
    db = getFirestore();
    const userDataRef = doc(db, "user_data", email);
    const unsub = onSnapshot(userDataRef, (snap) => {
      setState(snap.data());
    });
  }, [app, auth]);
  return state;
};
export const useCurrentUserData = singletonHook(null, useCurrentUserDataImpl);

const useCurrentCounterImpl = () => {
  const [state, setState] = useState(null);
  app = useFirebaseApp();

  useEffect(() => {
    if (!app) return;
    const docRef = doc(db, "counter", "counter");
    const unsub = onSnapshot(docRef, (snap) => {
      setState(snap.data().count);
    });
  }, [app]);
  return state;
};
export const useCurrentCounter = singletonHook(null, useCurrentCounterImpl);

export async function TriggerCounter(params) {
  const counterRef = doc(db, "counter", "counter");
  await updateDoc(counterRef, { count: increment(-1) });
}

const useCurrentChallengeImpl = () => {
  const [state, setState] = useState(null);
  app = useFirebaseApp();

  useEffect(() => {
    if (!app) return;
    const docRef = doc(db, "counter", "challenge");
    const unsub = onSnapshot(docRef, (snap) => {
      setState(snap.data().count);
    });
  }, [app]);
  return state;
};
export const useCurrentChallenge = singletonHook(null, useCurrentChallengeImpl);

export async function TriggerChallenge(params) {
  const counterRef = doc(db, "counter", "challenge");
  await updateDoc(counterRef, { count: increment(-1) });
}
// ============================================================================
// Dev Only
// ============================================================================

const useUserDataImpl = () => {
  const [state, setState] = useState(null);
  app = useFirebaseApp();
  useEffect(() => {
    if (!app) return;
    db = getFirestore();
    const userDataRef = collection(db, "user_data");
    const unsub = onSnapshot(userDataRef, (snap) => {
      const content = [];
      snap.forEach((x) => {
        content.push({ ...x.data(), id: x.id });
      });
      // const header = Object.keys(content[0] || {});
      // header.splice(
      //   header.findIndex((x) => x === "id"),
      //   1
      // );
      const sorted = _.sortBy(content, "ts");
      sorted.map((x, index) => {
        if (!x.number) {
          admin_updateNumber(x.id, index + 1);
        }
      });
      const header = [
        "number",
        "title",
        "ticket_type",
        "ic_number",
        "fullname",
        "nickname",
        "email",
        "phone_number",

        "address",
        "poskod",
        "state",
        "country",

        "vegetarian",
        "challenge",
        "poster",
        "ts",
        "lpo",
      ];

      setState({ header, content });
    });
  }, [app]);
  return state;
};

export const useUserData = singletonHook(null, useUserDataImpl);

export async function admin_updateNumber(email, number) {
  const userDoc = doc(db, "user_data", email);
  try {
    await updateDoc(userDoc, { number: number });
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorCode);
    console.log(errorMessage);
  }
}

export async function admin_updateStatus(email) {
  const userDoc = doc(db, "user_data", email);
  try {
    await updateDoc(userDoc, { status: "complete" });
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorCode);
    console.log(errorMessage);
  }
}

export async function Admin_Add_Userdata(input) {
  let header = [];
  let content = [];
  let updateObjectArr = [];
  const row_arr = input.split("\n");
  header = row_arr[0].split("\t");
  for (let i = 1; i < row_arr.length; i++) {
    content.push(row_arr[i]);
  }
  const userDataRef = collection(db, "user_data");
  const batch = writeBatch(db);

  // batch.set(doc(db, "user_data", "header"), { data: header });
  setDoc(doc(db, "user_data", "header"), { data: header });
  content.map((row) => {
    const row_arr = row.split("\t");
    const update_obj = {};
    header.map((head, i) => {
      update_obj[head] = row_arr[i];
    });
    updateObjectArr.push(update_obj);
  });
  // batch.set(doc(db, "user_data", row_arr[0]), update_obj);
  // });
  console.log(updateObjectArr);
  // const res = await batch.commit();
  // console.log(res);

  const batches = _.chunk(updateObjectArr, 500).map((updateObject) => {
    const batch = writeBatch(db);
    updateObject.forEach((row) => {
      batch.set(doc(db, "user_data", row.id), row);
    });
    return batch.commit();
  });

  await Promise.all(batches);
}

export async function Admin_AddUser(id, data_obj, callback) {
  const userDataDoc = doc(db, "user_data", id);
  const res = await setDoc(userDataDoc, data_obj);
  callback(true);
}

export async function Admin_DelUser(id, callback) {
  const userDataDoc = doc(db, "user_data", id);
  const res = await deleteDoc(userDataDoc);
  callback(true);
}
