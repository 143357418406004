import React, { useState, useEffect, useRef } from "react";

import bg from "../asset/image/login/registration-bg.jpeg";

import NavBar from "./Component/Navbar";
import FooterBar from "./Component/Footer";

import useWindowSize from "./Component/useWindowSize";

import { FireSignIn } from "../firebase/useFireUser";
export default function LoginPage() {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const ref_email = useRef();
  const ref_pass = useRef();

  const windowSize = useWindowSize();
  if (!windowSize) return null;

  async function oc_login(params) {
    if (isLoading) return;
    setError("");

    const email = ref_email.current.value;
    const pass = ref_pass.current.value;

    // sanitize input
    if (email === "") {
      setError("IC number is required");
      return;
    }
    if (pass === "") {
      setError("Phone number is required");
      return;
    }

    // start execute
    setIsLoading(true);

    const res = await FireSignIn(email + "@user.com", pass);
    setIsLoading(false);

    if (!res.isSuccess) {
      setError(res.errorMessage);
    }
  }

  return (
    <div
      className="flex flex-col w-full"
      style={{ minHeight: "100vh", backgroundImage: `url(${bg})` }}
    >
      {/* ========== ROW 1 ========== */}
      <div className="flex-1 flex-center">
        <div
          className="container mx-auto bg-white px-10 py-10 text-blue-ci font-medium"
          style={{ maxWidth: "400px" }}
        >
          <p className="text-2xl">Login</p>

          <div className="space-y-5">
            <div className="mt-10">
              <p className="uppercase text-gray-600">IC number</p>
              <input
                onChange={(e) => {
                  const val = e.target.value;
                  const res = val.replace(/\D/g, "");
                  e.target.value = res;
                }}
                ref={ref_email}
                className="border w-full h-10 mt-3 px-3"
                type="text"
                placeholder="999999999999"
                name=""
                id=""
              />
            </div>

            <div className="mt-5">
              <p className="uppercase text-gray-600">phone number</p>
              <input
                onChange={(e) => {
                  const val = e.target.value;
                  const res = val.replace(/\D/g, "");
                  e.target.value = res;
                }}
                ref={ref_pass}
                className="border w-full h-10 mt-3 px-3"
                type="text"
                placeholder="0123456789"
                name=""
                id=""
              />
            </div>
          </div>

          {/* ========== ERROR ========== */}
          {error !== "" && <p className="text-red-500 mt-3">{error}</p>}

          <div
            onClick={oc_login}
            className="mt-10 bg-blue-ci text-center p-2 h-12 text-white flex-center capitalize cursor-pointer"
          >
            {isLoading ? (
              <span className="animate-spin material-icons">autorenew</span>
            ) : (
              "login"
            )}
          </div>
          <a href="/register">
            <div className="mt-5 bg-gray-100 shadow text-center p-2 h-12 text-gray-600 flex-center capitalize cursor-pointer">
              register
            </div>
          </a>
          <a href="/">
            <div className="mt-5 bg-gray-100 shadow text-center p-2 h-12 text-gray-600 flex-center capitalize cursor-pointer">
              back to home
            </div>
          </a>
        </div>
      </div>
      {/* ========== FOOTER BAR ========== */}
      <FooterBar />
    </div>
  );
}
