import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import bg1_desktop from "../asset/image/home/bg1-desktop.png";
import bg1_mobile from "../asset/image/home/bg1-mobile.png";
import bg2 from "../asset/image/home/bg2.png";
import s1 from "../asset/image/home/s1.png";
import s2 from "../asset/image/home/s2.png";
import avatar_president from "../asset/image/home/avatar-president.png";
import logo1 from "../asset/image/home/logo1.png";
import logo2 from "../asset/image/home/logo2.png";
import logo3 from "../asset/image/home/logo3.png";
import logo4 from "../asset/image/home/logo4.png";
import logo5 from "../asset/image/home/logo5.png";
import logo6 from "../asset/image/home/logo6.png";
import contractor_logo from "../asset/image/home/contractor_logo.png";
import bg3 from "../asset/image/home/bg3.png";
import tix from "../asset/image/home/tix.png";

import apex from "../asset/image/home/apex.png";
import duerr from "../asset/image/home/duerr.png";
import quantum from "../asset/image/home/quantum.png";
import caredes from "../asset/image/home/caredes.png";

import NavBar from "./Component/Navbar";
import FooterBar from "./Component/Footer";

import useWindowSize from "./Component/useWindowSize";
import { useCurrentCounter } from "../firebase/useFireEvent";
import { useFireUser } from "../firebase/useFireUser";

export default function HomePage() {
  const windowSize = useWindowSize();
  const counter = useCurrentCounter();
  const user = useFireUser();
  if (!windowSize) return null;

  return (
    <div className="flex flex-col w-full" style={{ minHeight: "100vh" }}>
      {/* ========== NAV BAR ========== */}
      <NavBar />
      <div className="bg-white mt-28 md:mt-40 mb-20 text-blue-ci container mx-auto px-4">
        <div className="font-bold text-blue-ci text-3xl md:text-3xl max-w-3xl uppercase">
          7<sup>th</sup> Malaysian Dental Technologist Conference & Trade
          Exhibition
        </div>
        <p className="font-medium text-sm md:text-lg mt-5 max-w-3xl">
          The 7th Malaysian Dental Technologist Conference & Trade Exhibition is
          held annually by routine and collaboration between Malaysia Dental
          Technologist Association (MDTA) and Persatuan Juruteknologi Pergigian
          Malaysia (PJPM)
        </p>
        <p className="font-medium text-sm md:text-lg mt-5 max-w-3xl">
          The 7th Malaysian Dental Technologists Conference & Trade Exhibition
          is supported by Kesatuan Juruteknologi Pergigian Semenanjung Malaysia.
        </p>
        <p className="font-medium text-sm md:text-lg mt-5 max-w-3xl">
          The participants and attendees will comprise of Dental Technologists
          from the public and private sectors throughout Malaysia and from
          overseas as well.
        </p>
        <p className="font-medium text-sm md:text-lg mt-5 max-w-3xl">
          The theme for t h e 7 t h Malaysian Dental Technologist Conference &
          Trade Exhibition 2022 is "
          <strong>Evolution of Innovation & Technology in Dentistry</strong>".
          At 7th MDTC, you will have the opportunity to:
        </p>
        <p className="font-medium text-sm md:text-lg mt-5 max-w-3xl">
          ❖ Learn new ideas and make you more effective and efficient at work.
        </p>
        <p className="font-medium text-sm md:text-lg mt-5 max-w-3xl">
          ❖ Networking: Opportunity to form new relationships, share your
          interest and experience, exchange perspectives, offer encouragement
          and solutions to common problems and advise how to handle challenges.
        </p>
        <p className="font-medium text-sm md:text-lg mt-5 max-w-3xl">
          ❖ The flip side of learning of new things is relearning the classic
          techniques. This creates opportunities for greater focus and
          reflection that could help elevate your business to next level.
        </p>
        <p className="text-sm md:text-lg mt-7 max-w-3xl font-bold ">
          7th MDTC Events Particulars
        </p>
        <p className="font-medium text-sm md:text-lg  max-w-3xl">
          Date : 01 – 03 July 2022
        </p>
        <p className="font-medium text-sm md:text-lg  max-w-3xl">
          Time : 9:00 am to 6:00pm
        </p>
        <p className="font-medium text-sm md:text-lg  max-w-3xl">
          Venue : Level 14, Berjaya Time Square Hotel Kuala Lumpur
        </p>
        <p className="font-medium text-sm md:text-lg max-w-3xl">
          Enquiry: mdta.event@gmail.com
        </p>
      </div>
      {/* ========== ROW 1 ========== */}
      <div
        className="ROW1"
        style={{
          height: "90vh",
          minHeight: "500px",
          backgroundImage: `url(${
            windowSize.isMobile ? bg1_mobile : bg1_desktop
          })`,
          backgroundSize: "cover",
          backgroundPosition: "right",
        }}
      >
        <div className="container mx-auto h-full relative">
          <div className="absolute left-5 top-1/2 md:top-1/2 transform -translate-y-1/2 text-blue-ci">
            <p className="font-medium text-sm md:text-lg">
              7th Malaysian Dental Technologist Conference & Trade Exhibition
            </p>
            <p className="font-bold  text-3xl md:text-5xl uppercase mt-8">
              Evolution of Innovation
              <br /> & Technology in Dentistry
            </p>

            <div className="flex mt-10 md:mt-20">
              {counter === null ? null : counter > 0 ? (
                <Link to="/register">
                  <div className="bg-blue-ci text-white font-medium rounded-full flex-center py-3 px-8 cursor-pointer">
                    {user ? "Check payment status" : "Register to join now"}
                  </div>
                </Link>
              ) : (
                <div className=" text-blue-ci font-medium rounded-full flex-center py-3 px-8 cursor-pointer border-2 border-blue-ci">
                  Ticket sold out
                </div>
              )}
            </div>

            <div className="flex mt-2">
              <a
                target={"_blank"}
                download
                href="https://firebasestorage.googleapis.com/v0/b/mdta-event.appspot.com/o/7th%20MDTC%20website%20registeration.pdf?alt=media&token=11479a66-8270-4b8c-b201-2814873a1f17"
                className="bg-blue-ci text-white shadow-lg border font-medium rounded-full flex-center py-3 px-8 cursor-pointer"
              >
                Download Event Info
              </a>
            </div>
            <div className="flex mt-2">
              <a
                target={"_blank"}
                download
                href="https://firebasestorage.googleapis.com/v0/b/mdta-event.appspot.com/o/bts_booking_form.pdf?alt=media&token=e78dec11-ca1d-4518-a0c8-22eca2f4b20f"
                className="bg-blue-ci text-white shadow-lg border font-medium rounded-full flex-center py-3 px-8 cursor-pointer"
              >
                Download Hotel Booking Form
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* ========== ROW 2 ========== */}
      <div
        className="ROW2 py-10 flex-center"
        style={{
          minHeight: "100vh",
          backgroundImage: `url(${bg2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
        }}
      >
        <div
          className="container mx-auto h-full relative flex flex-col"
          style={{ maxWidth: "1150px" }}
        >
          <div className="row flex flex-col lg:flex-row w-full">
            <img
              src={s1}
              alt=""
              className="object-contain px-10"
              style={{ maxHeight: "20rem" }}
            />

            <div className="p-10">
              <p className="text-blue-ci text-2xl md:text-4xl font-bold">
                Technological Innovations in the World of Dentistry
              </p>
              <p className="text-gray-600 mt-5 font-medium">
                The dental field is currently undergoing a period of rapid
                change and development. There are dozens of new inventions and
                techniques that are helping dentists give their patients an
                advanced level of care and the best chance at achieving a
                healthy smile.
              </p>
            </div>
          </div>
          <div className="row flex flex-col lg:flex-row-reverse  w-full mt-20">
            <img
              className="object-contain px-10"
              style={{ maxHeight: "20rem" }}
              src={s2}
              alt=""
            />
            <div className="p-10">
              <p className="text-blue-ci text-2xl md:text-4xl font-bold">
                Technologies Behind of Oral Health
              </p>
              <p className="text-gray-600  mt-5 font-medium">
                Many Americans today enjoy excellent oral health and are keeping
                their natural teeth throughout their lives. But this is not the
                case for everyone. Cavities are still the most prevalent chronic
                disease of childhood.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* ========== ROW 3 ========== */}
      <div
        className="ROW2 py-10 bg-blue-ci flex-center"
        style={{
          minHeight: "35rem",
        }}
      >
        <div
          className="container mx-auto flex-center flex-col text-white text-center font-medium px-3"
          style={{ maxWidth: "1150px" }}
        >
          <img src={avatar_president} alt="" />
          <p className="mt-10" style={{ maxWidth: "72ch" }}>
            The MDTA was founded in the year of 1953 and continues to stand
            strong and united with a team of professional committees and
            dedicated members. We now have 800 members and we continue to grow.{" "}
          </p>
          <p className="text-xl mt-10 font-bold">– Mr. Steven Ooi</p>
          <p className="text-gray-400">MDTA President</p>
        </div>
      </div>

      {/* ========== ROW 4 ========== */}
      <div
        className="ROW4 py-10 flex-center"
        style={{
          minHeight: "80vh",
          backgroundImage: `url(${bg3})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
        }}
      >
        <div
          className="container mx-auto h-full relative flex flex-col"
          style={{ maxWidth: "1150px" }}
        >
          <div className="row flex justify-center w-full px-5">
            <a href="/register">
              <img
                src={tix}
                alt=""
                className="object-contain cursor-pointer"
                style={{ maxHeight: "25rem" }}
              />
            </a>
            {/* 
            <div className="bg-gray-100 flex flex-col justify-center pl-10 pr-20 text-blue-ci text-sm 2xl:text-bae">
              <p
                className="text-xl 2xl:text-2xl font-bold"
                style={{ maxWidth: "25ch" }}
              >
                7th Malaysia Dental Technologist Conference & Exhibition
              </p>
              <p className="mt-5">01 – 03 July 2022</p>
              <p className="a">14th Floor, Berjaya Time Square Hotel</p>
              <div className="flex mt-10">
                <div className="bg-blue-ci rounded-full px-8 py-3 text-white font-bold">
                  Get Ticket Now
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>

      {/* ========== ROW 5 ========== */}
      <div
        className="ROW5 py-10 bg-white flex-center flex-col"
        style={{
          minHeight: "25rem",
        }}
      >
        <div className="capitalize text-center font-medium text-lg">
          Supporter
        </div>
        <div
          className="container mx-auto grid grid-cols-3 md:grid-cols-5"
          style={{ maxWidth: "1150px" }}
        >
          <img src={logo1} alt="" />
          <img src={logo2} alt="" />
          <img src={logo3} alt="" />
          <img src={logo6} alt="" />
          <img src={logo4} alt="" />
          <img src={logo5} alt="" />
        </div>
      </div>

      {/* ========== ROW 5 ========== */}
      <div
        className="ROW5 py-10 bg-white flex-center flex-col"
        style={{
          minHeight: "15rem",
        }}
      >
        <div className="capitalize text-center font-medium text-lg">
          Main contractor
        </div>
        <div
          className="container mx-auto grid grid-cols-3 md:grid-cols-5"
          style={{ maxWidth: "1150px" }}
        >
          <img src={contractor_logo} alt="" />
        </div>
      </div>

      {/* ========== ROW 5 ========== */}
      <div
        className="ROW5 py-10 bg-white flex-center flex-col"
        style={{
          minHeight: "25rem",
        }}
      >
        <div className="capitalize text-center font-medium text-lg">
          Sponsors
        </div>

        <div
          className="container mx-auto flex mt-10"
          style={{ maxWidth: "1150px" }}
        >
          <div className="flex flex-col">
            <div className="text-gray-500 underline mt-4">Platinium</div>
            <img src={apex} alt="" className=" filter grayscale " />
          </div>

          <div className="flex flex-col">
            <div className="text-gray-500 underline mt-4">Gold</div>
            <div className="flex">
              <img src={caredes} alt="" className=" filter grayscale w-1/2" />
              <img src={quantum} alt="" className=" filter grayscale w-1/2" />
            </div>
          </div>

          <div className="flex flex-col">
            <div className="text-gray-500 underline mt-4">Silver</div>
            <img src={duerr} alt="" className=" filter grayscale" />
          </div>
        </div>
      </div>

      {/* ========== FOOTER BAR ========== */}
      <FooterBar />
    </div>
  );
}
