import React, { useState, useEffect, useRef } from "react";
import {
  Admin_AddUser,
  Admin_Add_Userdata,
  Admin_DelUser,
  useUserData,
  admin_updateStatus,
} from "../firebase/useFireEvent";

import { FireSignOut } from "../firebase/useFireUser";
import bg from "../asset/image/home/bg2.png";

import ScrollContainer from "react-indiana-drag-scroll";
import { filter } from "lodash";
import { ToggleUserPopup, useAdminState } from "../singleton/AdminState";
import { useHistory } from "react-router-dom";
import { FireSignIn, useFireUser } from "../firebase/useFireUser";

import Lightbox from "react-awesome-lightbox";
// You need to import the CSS only once
import "react-awesome-lightbox/build/style.css";
import moment from "moment";

var _ = require("lodash");
const ticket_price = {
  mdta_member: 450,
  pjpm: 450,
  student: 400,
  non_member: 1200,
};
const challenge_price = { join: 100, no: 0 };

const email = `<!DOCTYPE html>

<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
<head>
<title></title>
<meta content="text/html; charset=utf-8" http-equiv="Content-Type"/>
<meta content="width=device-width, initial-scale=1.0" name="viewport"/>
<!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
<style>
		* {
			box-sizing: border-box;
		}

		body {
			margin: 0;
			padding: 0;
		}

		a[x-apple-data-detectors] {
			color: inherit !important;
			text-decoration: inherit !important;
		}

		#MessageViewBody a {
			color: inherit;
			text-decoration: none;
		}

		p {
			line-height: inherit
		}

		@media (max-width:920px) {
			.icons-inner {
				text-align: center;
			}

			.icons-inner td {
				margin: 0 auto;
			}

			.row-content {
				width: 100% !important;
			}

			.image_block img.big {
				width: auto !important;
			}

			.column .border {
				display: none;
			}

			table {
				table-layout: fixed !important;
			}

			.stack .column {
				width: 100%;
				display: block;
			}
		}
	</style>
</head>
<body style="background-color: #f6f6f6; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
<table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #f6f6f6;" width="100%">
<tbody>
<tr>
<td>
<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
<tbody>
<tr>
<td>
<table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff; color: #000000; width: 900px;" width="900">
<tbody>
<tr>
<td class="column column-1" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 0px; padding-bottom: 5px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="100%">
<table border="0" cellpadding="0" cellspacing="0" class="image_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
<tr>
<td style="width:100%;padding-right:0px;padding-left:0px;">
<div align="center" style="line-height:10px"><img class="big" src="http://cdn.mcauto-images-production.sendgrid.net/f4f80a63e8e29d3f/253b7484-b0e7-4af3-8827-4418c4462427/900x331.jpg" style="display: block; height: auto; border: 0; width: 900px; max-width: 100%;" width="900"/></div>
</td>
</tr>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-2" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
<tbody>
<tr>
<td>
<table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff; color: #000000; width: 900px;" width="900">
<tbody>
<tr>
<td class="column column-1" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 5px; padding-bottom: 5px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="100%">
<table border="0" cellpadding="0" cellspacing="0" class="heading_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
<tr>
<td style="width:100%;text-align:center;padding-top:50px;">
<h1 style="margin: 0; color: #555555; font-size: 23px; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; line-height: 120%; text-align: center; direction: ltr; font-weight: 700; letter-spacing: normal; margin-top: 0; margin-bottom: 0;">Payment Receipt</h1>
</td>
</tr>
</table>
<table border="0" cellpadding="0" cellspacing="0" class="heading_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
<tr>
<td style="width:100%;text-align:center;padding-bottom:50px;">
<h1 style="margin: 0; color: #555555; font-size: 18px; font-family: Arial, Helvetica Neue, Helvetica, sans-serif; line-height: 120%; text-align: center; direction: ltr; font-weight: 700; letter-spacing: normal; margin-top: 0; margin-bottom: 0;">7thMDTC/REG[number]</h1>
</td>
</tr>
</table>
<table border="0" cellpadding="0" cellspacing="0" class="paragraph_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
<tr>
<td style="padding-top:10px;padding-right:10px;padding-bottom:30px;padding-left:10px;">
<div style="color:#000000;font-size:14px;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;font-weight:400;line-height:120%;text-align:left;direction:ltr;letter-spacing:0px;">
<p style="margin: 0; margin-bottom: 10px;">Dear [fullname],</p>
<p style="margin: 0; margin-bottom: 10px;">Thank you for registering for 7th Malaysian Dental Technologist Conference & Trade Exhibition!</p>
<p style="margin: 0; margin-bottom: 10px;"> </p>
<p style="margin: 0; margin-bottom: 10px;"><strong><em>Registration Details</em></strong></p>
<p style="margin: 0; margin-bottom: 10px;">Registration ID: [number]</p>
<p style="margin: 0; margin-bottom: 10px;">Registration Type: [ticket_type]</p>
<p style="margin: 0; margin-bottom: 10px;">Issued By: Malaysia Dental Technologist Association</p>
<p style="margin: 0; margin-bottom: 10px;">Conference: 7th Malaysian Dental Technologist Conference & Trade Exhibition</p>
<p style="margin: 0;">Conference Date: 1 - 3 July 2022  </p>
</div>
</td>
</tr>
</table>
<table border="0" cellpadding="0" cellspacing="0" class="paragraph_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
<tr>
<td style="padding-top:10px;padding-right:10px;padding-bottom:30px;padding-left:10px;">
<div style="color:#000000;font-size:14px;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;font-weight:400;line-height:120%;text-align:left;direction:ltr;letter-spacing:0px;">
<p style="margin: 0; margin-bottom: 10px;"><em><strong>Transaction Summary & Payment Details</strong></em></p>
<p style="margin: 0; margin-bottom: 10px;">Received with thanks the amount MYR [paid] as per details below.</p>
<p style="margin: 0; margin-bottom: 10px;">Total Charges: MYR [paid]</p>
<p style="margin: 0; margin-bottom: 10px;">Amount Paid: MYR [paid]</p>
<p style="margin: 0; margin-bottom: 10px;">Payment Mode: Cheque / Bank Transfer</p>
<p style="margin: 0;">Reciept No.: 7thMDTC/REG[number]</p>
</div>
</td>
</tr>
</table>
<table border="0" cellpadding="10" cellspacing="0" class="paragraph_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;" width="100%">
<tr>
<td>
<div style="color:#000000;font-size:14px;font-family:Arial, Helvetica Neue, Helvetica, sans-serif;font-weight:400;line-height:120%;text-align:left;direction:ltr;letter-spacing:0px;">
<p style="margin: 0; margin-bottom: 10px;"><strong>Note: </strong>Please save this email for future reference.</p>
<p style="margin: 0;">For any enquiry, kindly contact the secretariat at <a href="mailto:mdta1953@gmail.com" rel="noopener" style="color: #0068a5;" target="_blank"><strong>mdta.event@gmail.com / mdta1953@gmail.com</strong></a>.</p>
</div>
</td>
</tr>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-3" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
<tbody>
<tr>
<td>
<table align="center" border="0" cellpadding="0" cellspacing="0" class="row-content stack" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #ffffff; color: #000000; width: 900px;" width="900">
<tbody>
<tr>
<td class="column column-1" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; vertical-align: top; padding-top: 5px; padding-bottom: 5px; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;" width="100%">
<table border="0" cellpadding="0" cellspacing="0" class="icons_block" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;" width="100%">
<tr>
<td style="vertical-align: middle; color: #9d9d9d; font-family: inherit; font-size: 15px; padding-bottom: 5px; padding-top: 5px; text-align: center;">

</td>
</tr>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table>
</td>
</tr>
</tbody>
</table><!-- End -->
</body>
</html>
`;
function UserPopUp(props) {
  const userdata = useUserData();
  const adminState = useAdminState();
  const formRef = useRef();

  if (!adminState) return null;
  if (!userdata) return null;

  const firstHeader = userdata.header[0];
  const targetUser = userdata.content.find((el) => {
    return el[firstHeader] === adminState.targetUser;
  });

  function Hide() {
    userdata.header.map((el, i) => {
      formRef.current[i].value = "";
    });

    ToggleUserPopup();
  }
  return (
    <div
      className={`absolute w-screen h-screen z-10 flex justify-center items-center transition ${
        adminState.showUserPopup
          ? "opacity-100"
          : "opacity-0 pointer-events-none"
      }`}
    >
      <div
        onClick={Hide}
        className="absolute w-full h-full top-0 left-0"
        style={{ backgroundColor: "rgba(0 0 0 / 30%)" }}
      ></div>
      <div
        className={`bg-white rounded-lg shadow w-full flex flex-col p-6 transition delay-100 duration-300 transform ${
          adminState.showUserPopup
            ? "opacity-100 translate-y-0"
            : "opacity-0 pointer-events-none translate-y-10"
        }`}
        style={{ maxWidth: "450px", maxHeight: "75%" }}
      >
        <p className="capitalize text-lg font-semibold">
          {targetUser ? "edit user" : "add user"}
        </p>

        <form
          ref={formRef}
          onSubmit={(e) => {
            e.preventDefault();
            if (targetUser) {
              const newData = new Array(userdata.header.length);
              Object.entries(targetUser).map(([key, val]) => {
                newData[userdata.header.findIndex((el) => el === key)] = val;
              });
              Object.values(e.target).map((x, i) => {
                if (x.nodeName === "INPUT") {
                  newData[i] =
                    x.value === "" ? newData[i] : x.value.toLowerCase();
                }
              });
              const data_obj = {};
              userdata.header.forEach((head, i) => {
                data_obj[head] = newData[i];
              });
              Admin_AddUser(newData[0], data_obj, (success) => {
                if (success) {
                  Hide();
                }
              });
            } else {
              const data_str = [];
              Object.values(e.target).map((x) => {
                if (x.nodeName === "INPUT") {
                  data_str.push(x.value.toLowerCase());
                }
              });

              const data_obj = {};
              userdata.header.forEach((head, i) => {
                data_obj[head] = data_str[i];
              });
              Admin_AddUser(data_str[0], data_obj, (success) => {
                if (success) {
                  Hide();
                }
              });
            }
          }}
        >
          <div className="grid grid-cols-2 gap-y-4 mt-8">
            {userdata.header.map((el, i) => {
              return (
                <div key={i} className="a">
                  <p className="capitalize">
                    {el}
                    {i === 0 && <span className="text-red-600">*</span>}
                  </p>
                  <input
                    disabled={targetUser && i === 0}
                    className={`border rounded-md h-8 px-4 ${
                      targetUser && i === 0 ? "placeholder-black" : ""
                    }`}
                    placeholder={targetUser ? targetUser[el] : ""}
                    type="text"
                    name=""
                    id=""
                  />
                </div>
              );
            })}
          </div>

          {/* ========== REMOVE USER ========== */}
          {targetUser && (
            <button
              onClick={(e) => {
                e.preventDefault();
                Admin_DelUser(targetUser[userdata.header[0]], () => {
                  Hide();
                });
              }}
              className="bg-red-400 rounded-lg font-semibold flex justify-center items-center text-sm mt-10 -mb-7 h-10 w-full"
              style={{
                padding: "6px 16px",
                boxShadow: "rgb(251 191 36 / 24%) 0px 8px 16px 0px",
              }}
            >
              delete user
            </button>
          )}

          <button
            className="bg-yellow-400 rounded-lg font-semibold flex justify-center items-center text-sm mt-10 h-10 w-full"
            style={{
              padding: "6px 16px",
              boxShadow: "rgb(251 191 36 / 24%) 0px 8px 16px 0px",
            }}
          >
            {targetUser ? "save changes" : "add user"}
          </button>
        </form>
      </div>
    </div>
  );
}
export default function AdminPage() {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const [selectImg, setSelectImg] = useState(null);
  const user = useFireUser();
  const [currentTab, setCurrentTab] = useState("user");
  function oc_logout(param) {
    FireSignOut();
  }
  async function oc_login(e) {
    e.preventDefault();
    if (isLoading) return;
    setError("");

    const data = new FormData(e.target);
    const input = Object.fromEntries(data.entries());

    // sanitize
    const email = input.email;
    const pass = input.pass;

    // start execute
    setIsLoading(true);

    const res = await FireSignIn(email, pass);
    setIsLoading(false);

    if (!res.isSuccess) {
      setError(res.errorMessage);
    }
  }

  if (!user) {
    return (
      <div
        className="flex flex-col w-full flex-center px-2"
        style={{
          minHeight: "100vh",
          backgroundImage: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="bg-white rounded-md p-5 text-blue-ci">
          <p className="font-bold  text-3xl md:text-2xl uppercase">
            Evolution of Innovation
            <br /> & Technology in Dentistry
          </p>
          <p className="font-medium text-sm md:text-base mt-3">
            7th Malaysia Dental Technologist Conference & Exhibition
          </p>
          <div className="h-px w-full bg-gray-300 my-8"></div>
          <p className="font-bold text-xl">Admin Login</p>

          <form onSubmit={oc_login}>
            <div className="flex flex-col w-full">
              <p className="text-gray-400 mt-3 text-sm">email</p>
              <input
                required
                className="h-12 border p-2 rounded-md"
                type="email"
                name="email"
              />
              <p className="text-gray-400 mt-3  text-sm">password</p>
              <input
                required
                className="h-12 border p-2 rounded-md"
                type="password"
                name="pass"
              />
            </div>

            {/* ========== ERROR ========== */}
            {error !== "" && <p className="text-red-500 mt-2">{error}</p>}

            <button
              className="cursor-pointer mt-8 font-bold bg-blue-ci text-center w-full h-12 text-white rounded-md flex-center
          "
            >
              {isLoading ? (
                <span className="animate-spin material-icons">autorenew</span>
              ) : (
                "Login"
              )}
            </button>
          </form>
        </div>
      </div>
    );
  }

  if (!user.email.includes("@admin.com")) {
    history.push("/");
  }

  return (
    <div
      className="ADMIN flex flex-col overflow-hidden text-sm"
      style={{ minHeight: "100vh", maxHeight: "100vh" }}
    >
      {selectImg && (
        <Lightbox
          image={selectImg}
          title=""
          onClose={() => {
            setSelectImg(null);
          }}
        />
      )}
      <UserPopUp />
      <div className="h-16 py-2 px-5 flex justify-between items-center shadow">
        <p className="font-bold text-xl text-blue-ci">Admin Dashboard</p>
        <div
          onClick={oc_logout}
          className="cursor-pointer bg-blue-ci text-white font-bold p-2 px-5 rounded-md flex-center"
        >
          logout
        </div>
      </div>
      {/* ===== RIGHT ===== */}
      <div className="RIGHT flex-1 flex flex-col md:px-10 px-6 min-w-0 min-h-0 overflow-y-auto">
        {/* ===== CONTENT ===== */}
        <div className="mt-5 self-center w-full flex-1 min-h-0 flex flex-col">
          {currentTab === "user" && <TabUser {...{ setSelectImg }} />}
          {currentTab === "import" && <TabImport />}
        </div>
      </div>
    </div>
  );
}

function TabUser({ setSelectImg }) {
  const userdata = useUserData();

  const [customFilter, setCustomFilter] = useState("");

  const [filterArr, setFilterArr] = useState([]);
  const [hideArr, setHideArr] = useState([]);

  const [sortObj, setSortObj] = useState({ header: [], asc: [] });

  let process_header = [];
  let process_content = [];

  async function oc_approve(email) {
    await admin_updateStatus(email);
  }

  // process data
  if (userdata) {
    process_header = userdata.header;
    process_content = userdata.content;
    process_content = _.orderBy(process_content, ["number"], sortObj.asc);

    // remove unfit
    if (filterArr.length > 0) {
      let toRemove = [];
      for (let i = 0; i < filterArr.length; i++) {
        const [keyword, header] = filterArr[i].split("[@]");
        for (let j = 0; j < process_content.length; j++) {
          const row = process_content[j];
          if (!row[header].includes(keyword)) {
            toRemove.push(row);
          }
        }
      }
      process_content = _.difference(process_content, toRemove);
    }

    // custom filter
    if (customFilter !== "") {
      const filtered = [];
      process_content.map((row) => {
        const col_arr = Object.values(row);
        for (let i = 0; i < col_arr.length; i++) {
          if (
            typeof col_arr[i] === "string" &&
            col_arr[i].includes(customFilter)
          ) {
            filtered.push(row);
            break;
          }
        }
      });
      process_content = filtered;
    }

    if (sortObj.header.length > 0) {
      process_content = _.orderBy(process_content, sortObj.header, sortObj.asc);
    }
  }

  if (!userdata)
    return (
      <div className="flex justify-center items-center">
        <span className="material-icons animate-spin">autorenew</span>
      </div>
    );

  return (
    <div className="flex-1 TABCONTENT pb-4 flex flex-col min-h-0 overflow-y-auto">
      {/* ===== CONTENT ===== */}
      <div
        className="CONTENT flex-1 overflow-y-auto min-h-0 bg-white overflow-hidden rounded-xl flex flex-col"
        style={{
          boxShadow:
            "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px",
        }}
      >
        {/* ===== HEADER ===== */}
        <div className="flex-none py-4 px-6" style={{ minHeight: "64px" }}>
          <input
            onChange={(e) => {
              setCustomFilter(e.target.value);
            }}
            value={customFilter}
            className="border rounded-xl w-full"
            type="text"
            name=""
            placeholder="Search user..."
            style={{ padding: "16px 16px" }}
            id=""
          />
        </div>

        <ScrollContainer className="flex-none w-full max-h-8 flex items-center space-x-4 px-4 overflow-x-auto">
          {hideArr.length + filterArr.length > 0 && (
            <span
              onClick={() => {
                setHideArr([]);
                setFilterArr([]);
              }}
              className="flex-none border-red-500 w-5 h-5 material-icons text-sm rounded-full flex items-center justify-center transition bg-red-500 text-white cursor-pointer opacity-50 hover:opacity-100"
            >
              close
            </span>
          )}
          {hideArr.map((x, i) => {
            return (
              <div
                key={i}
                onClick={() => {
                  setHideArr((prev) => {
                    const newState = [...prev];
                    newState.splice(
                      prev.findIndex((keyword) => keyword === x),
                      1
                    );
                    return newState;
                  });
                }}
                className="group h-8 bg-gray-500 text-white rounded-full flex items-center justify-center px-4 font-normal hover:font-medium cursor-pointer capitalize text-xs  whitespace-nowrap"
              >
                {x}
                <span className="material-icons ml-2 text-sm group-hover:bg-red-500 group-hover:text-white rounded-full w-4 h-4 flex items-center justify-center transition">
                  close
                </span>
              </div>
            );
          })}
          {filterArr.map((x, i) => {
            return (
              <div
                key={i}
                onClick={() => {
                  setFilterArr((prev) => {
                    const newState = [...prev];
                    newState.splice(
                      prev.findIndex((keyword) => keyword === x),
                      1
                    );
                    return newState;
                  });
                }}
                className="group h-8 bg-yellow-300 rounded-full flex items-center justify-center px-4 font-normal hover:font-medium cursor-pointer capitalize text-xs whitespace-nowrap"
              >
                {x.split("[@]")[0]}
                <span className="material-icons ml-2 text-sm group-hover:bg-red-500 group-hover:text-white rounded-full w-4 h-4 flex items-center justify-center transition ">
                  close
                </span>
              </div>
            );
          })}
        </ScrollContainer>

        <div className="flex-1 h-40 WRAPPER overflow-y-auto min-h-0 min-w-0 w-full text-sm">
          <table className="table-auto w-full">
            {process_header.length > 0 && (
              <thead className="font-medium text-left">
                <tr className="border-b border-gray-100">
                  {process_header.map((header_name, i) => {
                    // check for hide
                    if (hideArr.includes(header_name)) return null;
                    // check of order
                    const index = sortObj.header.findIndex(
                      (el) => el === header_name
                    );
                    const isSort = index !== -1;

                    return (
                      <th
                        key={i}
                        className="p-4 font-medium capitalize whitespace-nowrap"
                      >
                        <div className="flex items-center">
                          <p
                            onClick={() => {
                              setHideArr((prev) => {
                                const newState = [...prev];
                                if (
                                  newState.findIndex(
                                    (keyword) => keyword === header_name
                                  ) === -1
                                )
                                  newState.push(header_name);
                                return newState;
                              });
                            }}
                            className="hover:bg-gray-500 hover:text-white hover:line-through rounded-lg p-1 cursor-pointer"
                            style={{ textDecorationThickness: "2px" }}
                          >
                            {header_name}
                          </p>

                          <span
                            onClick={() => {
                              setSortObj((prev) => {
                                const newState = {
                                  header: [...prev.header],
                                  asc: [...prev.asc],
                                };
                                // add sort
                                if (!isSort) {
                                  newState.header.push(header_name);
                                  newState.asc.push("asc");
                                  return newState;
                                }

                                if (newState.asc[index] === "asc") {
                                  newState.asc[index] = "desc";
                                } else {
                                  newState.header.splice(index, 1);
                                  newState.asc.splice(index, 1);
                                }
                                return newState;
                              });
                            }}
                            className={`material-icons ml-2 cursor-pointer text-xl hover:bg-gray-200 rounded-full ${
                              isSort ? "opacity-100" : "opacity-50"
                            }`}
                          >
                            {`${
                              isSort
                                ? sortObj.asc[index] === "asc"
                                  ? "south"
                                  : "north"
                                : "swap_vert"
                            }`}
                          </span>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
            )}
            {process_content.length > 0 && (
              <tbody>
                {process_content.map((row, i) => {
                  console.log(row);
                  return (
                    <tr key={i} className="p-4 hover:bg-gray-50 ">
                      {process_header.map((x, i) => {
                        const value = row[x];
                        if (hideArr.includes(process_header[i])) return null;
                        if (x === "ts") {
                          return (
                            <td key={i} className="p-4  font-normal capitalize">
                              <div className="a">{moment(value).fromNow()}</div>
                            </td>
                          );
                        }
                        return (
                          <td key={i} className="p-4  font-normal capitalize">
                            <p
                              onClick={() => {
                                const tag = value + "[@]" + x;
                                setFilterArr((prev) => {
                                  const newState = [...prev];
                                  if (
                                    newState.findIndex(
                                      (keyword) => keyword === tag
                                    ) === -1
                                  )
                                    newState.push(tag);
                                  return newState;
                                });
                              }}
                              className="hover:bg-yellow-300 rounded-lg p-1 cursor-pointer"
                            >
                              {typeof value === "object"
                                ? value.toDate().toLocaleTimeString()
                                : value}
                            </p>
                          </td>
                        );
                      })}

                      <td className="p-4  font-normal capitalize">
                        {/* {row["receiptURL"] && (
                          <img
                            onClick={() => {
                              setSelectImg(row["receiptURL"]);
                            }}
                            src={row["receiptURL"]}
                            style={{ minWidth: "10rem" }}
                            alt=""
                          />
                        )} */}
                        {row["receiptURL"] && (
                          <a
                            target="_blank"
                            href={row["receiptURL"]}
                            className=""
                          >
                            <div className="bg-blue-ci flex-center rounded-md text-white font-medium py-2 mt-1 cursor-pointer px-2">
                              preview
                            </div>
                          </a>
                        )}
                      </td>
                      <td>
                        RM{" "}
                        {ticket_price[row.ticket_type] +
                          challenge_price[row.challenge]}
                        .00
                      </td>
                      <td className="p-4  font-normal capitalize">
                        {row["status"] === "pending" && (
                          <div
                            className="cursor-pointer bg-blue-ci p-2 font-bold text-white rounded-md text-center"
                            onClick={() => oc_approve(row.id)}
                          >
                            approve
                          </div>
                        )}
                        {row["status"] === "complete" && (
                          <div className="border-2  text-center border-blue-ci p-2 font-bold text-blue-ci rounded-md">
                            approved
                          </div>
                        )}
                      </td>

                      <td>
                        <div
                          onClick={() => {
                            const element = document.createElement("a");
                            const file = new Blob(
                              [
                                email
                                  .replaceAll(
                                    `[fullname]`,
                                    Capitalize(row["fullname"])
                                  )
                                  .replaceAll(
                                    `[nickname]`,
                                    Capitalize(row["nickname"])
                                  )
                                  .replaceAll(
                                    `[number]`,
                                    row["number"].toString().padStart(3, "0")
                                  )
                                  .replaceAll(
                                    `[ticket_type]`,
                                    row["ticket_type"]
                                  )
                                  .replaceAll(
                                    `[paid]`,
                                    ` ${
                                      ticket_price[row.ticket_type] +
                                      challenge_price[row.challenge]
                                    }.00`
                                  ),
                              ],
                              {
                                type: "text/html;charset=utf-8",
                              }
                            );
                            element.href = URL.createObjectURL(file);
                            element.download = `${row["number"]}.html`;
                            document.body.appendChild(element);
                            element.click();
                          }}
                          className="bg-red-400 rounded p-2 cursor-pointer"
                        >
                          download
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
        <div className="h-10 flex justify-end px-5 flex-none items-center">
          <p>{process_content.length} Entries</p>
        </div>
      </div>
    </div>
  );
}
function TabImport(params) {
  const [input, setInput] = useState("");
  const [filterArr, setFilterArr] = useState([]);
  let header = [];
  let content = [];
  const userdata = useUserData();
  function onChange_input(e) {
    let s = e.target.value.toLowerCase();
    let arr = s.split("\n");
    let processed = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].trim() !== "") {
        processed.push(arr[i]);
      }
    }
    setInput(processed.join("\n"));
  }

  const row_arr = input.split("\n");
  header = row_arr[0].split("\t");
  for (let i = 1; i < row_arr.length; i++) {
    content.push(row_arr[i].split("\t"));
  }

  return (
    <div className="TABCONTENT h-screen pb-4 flex flex-col">
      {/* ===== HEADER ===== */}
      <div className="HEADER mb-10 flex justify-between items-center">
        <p className="capitalize text-2xl font-semibold">import</p>
        {/* ===== ADD USER ===== */}
        <div
          onClick={() => {
            Admin_Add_Userdata(input);
          }}
          className="bg-yellow-400 rounded-lg font-semibold flex items-center text-sm cursor-pointer"
          style={{
            padding: "6px 16px",
            boxShadow: "rgb(251 191 36 / 24%) 0px 8px 16px 0px",
          }}
        >
          <span className="material-icons w-4 flex justify-center mr-2 text-xl">
            add
          </span>{" "}
          Import Data
        </div>
      </div>

      {/* ===== CONTENT ===== */}
      <div
        className="CONTENT bg-white overflow-hidden rounded-xl flex flex-col"
        style={{
          boxShadow:
            "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px",
        }}
      >
        {/* ===== HEADER ===== */}
        <div className="flex-none py-4 px-6" style={{ minHeight: "64px" }}>
          <textarea
            className="border rounded-xl w-full"
            value={input}
            onChange={onChange_input}
            type="text"
            name=""
            placeholder="Paste content..."
            style={{ padding: "16px 16px" }}
            id=""
          />
        </div>

        <div className="flex-none w-full h-8 flex space-x-4 px-4">
          {filterArr.map((x, i) => {
            return (
              <div
                key={i}
                onClick={() => {
                  setFilterArr((prev) => {
                    const newState = [...prev];
                    prev.splice(
                      prev.findIndex((keyword) => keyword === x),
                      1
                    );
                    return newState;
                  });
                }}
                className="group bg-yellow-200 rounded-full flex items-center justify-center px-4 font-normal hover:font-medium cursor-pointer capitalize text-xs"
              >
                {x}
                <span className="material-icons ml-2 text-sm group-hover:bg-red-500 group-hover:text-white rounded-full w-4 h-4 flex items-center justify-center transition">
                  close
                </span>
              </div>
            );
          })}
        </div>
        <div className="WRAPPER overflow-auto min-h-0 min-w-0 w-full text-sm">
          <table className="table-auto">
            {header.length > 0 && (
              <thead className="font-medium text-left">
                <tr className="border-b border-gray-100">
                  <th className="p-4">
                    <input type="checkbox" value="" />
                  </th>
                  {header.map((x, i) => {
                    return (
                      <th
                        key={i}
                        className="p-4 font-medium capitalize whitespace-nowrap"
                      >
                        {x}
                      </th>
                    );
                  })}
                </tr>
              </thead>
            )}
            {content.length > 0 && (
              <tbody>
                {content.map((row, i) => {
                  return (
                    <tr key={i} className="p-4 hover:bg-gray-50">
                      <td className="p-4">
                        <input type="checkbox" value="" />
                      </td>

                      {header.map((x, i) => {
                        const value = row[i];
                        return (
                          <td key={i} className="p-4 font-normal capitalize">
                            <p
                              onClick={() => {
                                setFilterArr((prev) => {
                                  const newState = [...prev];
                                  if (
                                    newState.findIndex(
                                      (keyword) => keyword === value
                                    ) === -1
                                  )
                                    newState.push(value);
                                  return newState;
                                });
                              }}
                              className="hover:bg-yellow-300 rounded-lg p-1 cursor-pointer"
                            >
                              {value}
                            </p>
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            )}
          </table>
        </div>
        <div className="h-10 flex justify-end px-5 flex-none items-center">
          <p>{content.length} Entries</p>
        </div>
      </div>
    </div>
  );
}

function Capitalize(str) {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
}
